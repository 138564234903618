<template>
  <div v-if="adParams.adClient && adParams.adSlot && !unfilled" class="in-feed">
    <!-- The ad -->
    <ins
      ref="googleIns"
      class="adsbygoogle"
      style="display: block"
      data-ad-format="fluid"
      :data-ad-client="adParams.adClient"
      :data-ad-slot="adParams.adSlot"
      :data-ad-layout-key="adParams.layoutKey"
    ></ins>
    <div class="title">
      <BaseText class="light-70">{{ $t("app.advertisement") }}</BaseText>
    </div>
  </div>
</template>

<script setup lang="ts">
import { event } from "vue-gtag";
import { InFeedAdDisplayAt } from "~/types";

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

const props = defineProps<{
  displayAt: InFeedAdDisplayAt;
}>();

const emit = defineEmits<{
  (e: "update-ad-status", status: "filled" | "unfilled"): void;
}>();

const host = useCurrentHost();

onMounted(async () => {
  // Wait for the <ins> element to be rendered to the dom
  await nextTick();
  if (window.adsbygoogle) {
    try {
      window.adsbygoogle.push({});
    } catch (e) {
      console.error(e);
    }
  } else {
    try {
      window.adsbygoogle = [{}];
    } catch (e) {
      console.error(e);
    }
  }

  // Check if we have googlefc on the window 500ms after loading. If we don't we can assume the use of an ad blocker.
  setTimeout(() => {
    if (!window.googlefc) {
      unfilled.value = true;
    }
  }, 500);
});

/*
 * Use a mutation observer to detect changes in the ad status, if the ad is unfilled, emit that so we can load a fallback ad from our own backend
 */
const hasLogged = ref(false);
const googleIns = ref<HTMLElement>();
const unfilled = ref(false);
useMutationObserver(
  googleIns,
  () => {
    if (!googleIns.value) return;
    const adStatus = googleIns.value?.dataset.adStatus;

    if (adStatus === "filled" || adStatus === "unfilled") {
      unfilled.value = adStatus === "unfilled";
    }

    if (!hasLogged.value && adStatus === "filled") {
      event("ad_show", {
        type: "adsense",
      });
      hasLogged.value = true;
    }
  },
  {
    attributes: true,
    attributeFilter: ["data-ad-status"],
  }
);

const isVisible = useElementVisibility(googleIns);

// Log the impression when the ad is visible
watch(
  isVisible,
  (isVisible) => {
    if (isVisible) {
      // Log to Google Analytics only if the ad is filled
      if (googleIns.value?.dataset.adStatus === "filled" && !hasLogged.value) {
        event("ad_show", {
          type: "adsense",
        });
        hasLogged.value = true;
      }
    }
  },
  { immediate: true }
);

const adParams = computed(() => {
  let adClient;
  let adSlot;
  let layoutKey;

  // TODO: We probably want 10 or so different ad slots per display position, so that not every ad in the feed is the exact same ad.
  if (host.value === "allefolders") {
    adClient = "ca-pub-7814118610095655";
    switch (props.displayAt) {
      case InFeedAdDisplayAt.BrochuresGrid:
        adSlot = "7986088880"; // TODO: Get ad slot from JaFolders
        layoutKey = "+1x+s9-1r-45+eb";
        break;
      case InFeedAdDisplayAt.OffersGrid:
        adSlot = "7986088880"; // TODO: This is now the same as the brochures grid, change this to a different ad slot when created in google AdSense
        layoutKey = "+1p+s9-1r-45+eb";
        break;
      default:
        adSlot = null;
        layoutKey = "+1p+s9-1r-45+eb";
    }
  } else if (host.value === "promopromo") {
    adClient = "ca-pub-9832024765354406";
    switch (props.displayAt) {
      case InFeedAdDisplayAt.BrochuresGrid:
        adSlot = "2987025600";
        layoutKey = "+1p+s9-1r-45+eb";
        break;
      case InFeedAdDisplayAt.OffersGrid:
        adSlot = "2987025600"; // TODO: This is now the same as the brochures grid, change this to a different ad slot when created in google AdSense
        layoutKey = "+1p+s9-1r-45+eb";
        break;
      default:
        adSlot = null;
        layoutKey = "+1p+s9-1r-45+eb";
    }
  }

  return { adClient, adSlot, layoutKey };
});
</script>

<style lang="scss" scoped>
.in-feed {
  width: 100%;
  height: 100%;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;

  .adsbygoogle {
    flex: 1;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.25rem;
    width: 100%;

    .text {
      padding: 0 1rem;
    }
  }
}
</style>
